<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-text>
          <v-card-title>
            <v-icon>mdi-alert-outline</v-icon> 
            <span class="ml-2">Informações importantes - {{ pesquisa.titulo}}</span>
          </v-card-title>
          <v-row class="ml-2">
            <v-col lg="8" xl="8" md="8" sm="12">
              <v-row>
                <v-col cols="12">
                  <p class="text-justify"> {{ pesquisa.descricao }}</p>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="primary"> mdi-calendar-clock </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Data e hora final</v-list-item-title>
                        <v-list-item-subtitle>{{ pesquisa.data_inicio | dateFormatBr() }} {{ pesquisa.hora_inicio | timeFormatBr() }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item> 
                      <v-list-item-icon>
                        <v-icon color="primary"> mdi-clock-outline </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Data e hora final</v-list-item-title
                        >
                        <v-list-item-subtitle>{{ pesquisa.data_final | dateFormatBr() }} {{ pesquisa.hora_final | timeFormatBr() }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col lg="4" xl="4" md="4" sm="12" class="d-none d-md-flex">
              <img :src="persongem" width="150px" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="iniciarPesquisa()">
            Iniciar pesquisa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import imagem from "@/assets/images/misc/personagem_modal.png";
export default {
  name: "modal-apresentacao",
  data: () => ({
    persongem: imagem
  }),
  methods: {
    iniciarPesquisa()
    {
      this.dialog = false;
      this.formulario = true;
      /*this.$store.dispatch('pesquisas/atualizarStatusFormulario', this.dadosFormulario.formulario.uuid)
      .then((resp) => {
        if (resp != null || undefined)
        {
          this.dadosFormulario.formulario = resp
        }      
      })
      .catch((err) => {
        this.$store.dispatch('snackBarModule/openSnackBar', { color: 'error', timeout: 5000, text: err.data.message })
      })*/
    }
  },
  computed: {
    pesquisa: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaExterna"];
      }
    },
    dadosFormulario: {
      get() {
        return this.$store.getters["pesquisas/getDadosPesquisaExterna"];
      },
      set(value) {
        this.$store.dispatch('pesquisas/setDadosPesquisaExterna', value)
      }
    },
    dialog: {
      get() {
        return this.$store.getters["pesquisas/getPesquisaDialogExterna"];
      },
      set(value) {
        this.$store.dispatch('pesquisas/setPesquisaDialogExterna', value)
      }
    },
    formulario: {
      get() {
        return this.$store.getters["pesquisas/getHabilitarFormulario"];
      },
      set(value) {
        this.$store.dispatch('pesquisas/setHabilitarFormulario', value)
      }
    }
  }
};
</script>

<style>
</style>