import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c(VCardTitle,[_c(VIcon,[_vm._v("mdi-alert-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Informações importantes - "+_vm._s(_vm.pesquisa.titulo))])],1),_c(VRow,{staticClass:"ml-2"},[_c(VCol,{attrs:{"lg":"8","xl":"8","md":"8","sm":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.pesquisa.descricao))]),_c(VList,{attrs:{"two-line":""}},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-calendar-clock ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Data e hora final")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm._f("dateFormatBr")(_vm.pesquisa.data_inicio))+" "+_vm._s(_vm._f("timeFormatBr")(_vm.pesquisa.hora_inicio)))])],1)],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" mdi-clock-outline ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Data e hora final")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm._f("dateFormatBr")(_vm.pesquisa.data_final))+" "+_vm._s(_vm._f("timeFormatBr")(_vm.pesquisa.hora_final)))])],1)],1)],1)],1)],1)],1),_c(VCol,{staticClass:"d-none d-md-flex",attrs:{"lg":"4","xl":"4","md":"4","sm":"12"}},[_c('img',{attrs:{"src":_vm.persongem,"width":"150px"}})])],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.iniciarPesquisa()}}},[_vm._v(" Iniciar pesquisa ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }